import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"
import * as blogStyles from "../components/blog.module.scss"
import mask from "../img/HUES_Mask.png"
import UDmask from "../img/UnusualDemont_HUES_Mask.jpg"

const Mask = () => {
  return (
    <Layout>
      <Head title="Mask" />
      <div className={`${blogStyles.blogDiv}`}>
        <div className={`${blogStyles.blogPost}`}>
          <img
            src={UDmask}
            alt="The mask from Unusual Demont's debut EP, HUES."
          />

          <br />
          <a href={mask} download>
            <button className="unusualButton">Mask On</button>
          </a>
        </div>
        {/*
        <form
          name="subscribe-form"
          method="post"
          data-netlify="true"
          netlify-honeypot="bot-field"
          // data-netlify-recaptcha="true"

          // action="/"
        >
          <input type="hidden" name="form-name" value="subscribe-form" />

          <p class="hidden">
            <label>
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
          </p>

          <p>
            <label>
              First Name: <input type="text" name="fname" />
            </label>
          </p>
          <p>
            <label>
              Last Name: <input type="text" name="lname" />
            </label>
          </p>
          <p>
            <label>
              Email: <input type="text" name="email" />
            </label>
          </p>
          <div data-netlify-recaptcha="true"></div>

          <button
            className="unusualButtonMail"
            type="submit"
            style={{ fontSize: "10pt" }}
          >
            Subscribe
          </button>
        </form>

*/}
      </div>
    </Layout>
  )
}

export default Mask
